import React from "react";
import "./product.scss";
import { List, Button, Popover, Input, Select } from "antd";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { dataDefaultFetch, white_space_String } from "../../utils/dataOut";
import { postDataAPI } from "../../utils/fetchData";
const { Search } = Input;

export default function MainProduct() {
  const [searchFilter, setSearchFilter] = React.useState("");
  const childs = useOutletContext(); // <-- access context value
  const [selectf, setSelectF] = React.useState();

  const { state } = useLocation();
  const actf = React.useRef(true);

  const [products, setProducts] = React.useState(dataDefaultFetch);

  const [filters, setFilters] = React.useState({
    page: 0,
    limit_page: 9,
    filters: { is_delete: false },
  });

  async function getProduct() {
    try {
      setProducts({ ...products, loading: true });
      const res = await postDataAPI("/product/all", {
        filters: filters.filters,
        sort: filters?.sort,
      });

      setProducts({
        ...products,
        data: res.data?.data,
        loading: false,
        total: res.data?.total,
      });
    } catch (error) {
      setProducts({ ...products, data: null, loading: false, error: error });
    }
  }

  React.useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      const reset = { ...filters };
      if (state?.categories) {
        reset.filters["categories"] = state?.categories;
      }

      if (state?.search) {
        reset.filters["$text"] = state?.search?.["$text"];
      }
      actf.current = false;
      setTimeout(() => {
        setFilters(reset);
        actf.current = true;
      }, 300);
    }
  }, []);

  React.useEffect(() => {
    if (childs?.categories) {
      const reset = { ...filters };
      reset.filters["categories"] = childs?.categories;
      setFilters(reset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childs]);

  React.useEffect(() => {
    if (actf.current) {
      getProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSearchData = (data) => {
    if (!data) {
      const reset = { ...filters, page: 0, limit_page: 9 };
      delete reset.filters["$text"];
      setFilters(reset);
    } else {
      setFilters({
        ...filters,
        page: 0,
        limit_page: 9,
        filters: {
          $text: { $search: white_space_String(data) },
        },
      });
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="wrapinp">
        <div className="wrapinp1">
          <Search
            placeholder="Tìm kiếm sản phẩm..."
            onSearch={onSearchData}
            enterButton
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </div>
        <div className="wrapinp2">
          <Select
            style={{ width: 170 }}
            onChange={(e) => setSelectF(e)}
            value={selectf}
            placeholder="Mặc định"
            allowClear
            onClear={() => {
              const reset = { ...filters };
              reset.filters = { is_delete: false };
              delete reset["sort"];
              setFilters(reset);
              setSearchFilter("");
            }}
            onSelect={(e) => {
              const reset = { ...filters };
              if (!e) {
                reset.filters = { is_delete: false };
                delete reset["sort"];
                setFilters(reset);
                setSearchFilter("");
              } else {
                const ky = e?.split(" ");
                reset["sort"] = {};
                reset["sort"][`${ky[0]}`] = Number(ky[1]);
                setFilters(reset);
              }
            }}
            options={[
              { value: "", label: "All" },
              { value: "price 1", label: "Giá từ thấp đến cao" },
              { value: "price -1", label: "Giá từ cao đến thấp" },
            ]}
          />
        </div>
      </div>
      <div className="listp">
        <List
          itemLayout="horizontal"
          grid={{ gutter: 9, xl: 3, xxl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
          size="large"
          loading={products.loading}
          pagination={{
            pageSize: filters.limit_page,
            position: "bottom",
            align: "start",
            // defaultCurrent: 3,
            hideOnSinglePage: false,
            onChange: (e) => {
              setFilters({ ...filters, page: --e });
            },
            current: filters.page + 1,
            showQuickJumper: false,
            showSizeChanger: false,
            hideOnSinglePage: true,
            responsive: true,
            itemRender: (_, type, originalElement) => {
              if (type === "prev") {
                return <a>{"<<"} Trước</a>;
              }
              if (type === "next") {
                return <a>Sau {">>"}</a>;
              }
              return originalElement;
            },
          }}
          dataSource={products.data ? products.data : []}
          renderItem={(item) => (
            <div
              className="card_product"
              onClick={() => navigate(`/product/detail/${item._id}`)}
              key={item._id}
            >
              {/* {item.discount > 0 && <label>{item.discount}%</label>} */}
              <div>
                <img src={item.imgs[0].url} alt={item.imgs[0].name} />
              </div>
              <div>
                <div className="card_product_content">
                  <Popover content={item.title} trigger="hover">
                    <p>{item.title}</p>
                  </Popover>
                  {/* <p>
                    <span>VNĐ</span>
                    {Number(item.price).toLocaleString("en-US")}
                  </p> */}
                </div>
                <div className="card_product_btn">
                  <Button
                    className="btn_card_product"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate("/contact")
                    }}
                  >
                    Liên hệ
                  </Button>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </>
  );
}
