import React from "react";
import {
  Modal,
  Button,
  InputNumber,
  Table,
  Form,
  Input,
  Skeleton,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getDataAPI, postDataAPI } from "../../utils/fetchData";
import { dataDefaultFetch } from "../../utils/dataOut";
import Slider from "react-slick";

export default function Details() {
  const [avtiveslide, setActiveSlide] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [isOpF, setIsOpF] = React.useState(false);
  const [count, setCount] = React.useState(1);

  const [onePro, setOnePro] = React.useState(dataDefaultFetch);

  const { id } = useParams();
  const navigate = useNavigate();

  if (id && onePro.error) {
    navigate("/product");
  }

  React.useEffect(() => {
    if (id) {
      (async () => {
        setOnePro({ ...onePro, loading: true });
        try {
          const res = await getDataAPI(`product/one/${id}`);

          setOnePro({ ...onePro, data: res.data?.data, loading: false });
        } catch (error) {
          setOnePro({ ...onePro, data: null, loading: false, error: error });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChange = (value) => {
    setCount(value);
  };
  const columns = [
    {
      title: "Tên sản phẩm",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Mã sản phẩm",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Số lượng đặt",
      key: "count",
      render: () => <p>{count}</p>,
    },
    {
      title: "Tổng đơn",
      key: "total",
      render: (e) => (
        <p>
          {Number(
            count > 0 ? Math.round(e.price * count) : e.price
          ).toLocaleString("en-US")}
        </p>
      ),
    },
  ];

  const activeSlide = (e) => {
    setActiveSlide(e);
  };

  const onFinish = async (values) => {
    const sendmail = `
    <h1>Đơn Hàng ${onePro.data?.title} </h1>
    <div>
       <p>Mã sản phẩm: ${onePro.data?.code}</p>
       <p>Số lượng: ${count}</p>
       <p>Tổng đơn: ${Number(
         count > 0 ? Math.round(onePro.data?.price * count) : onePro.data?.price
       ).toLocaleString("en-US")} VNĐ</p>
    </div>

    <p>Tên: ${values.name}</p>
    <p>Điện thoại: ${values.phone}</p>
    <p>Email: ${values.email}</p>
    <p>Nội dung: ${values.content}</p>
    `;

    try {
      await postDataAPI("auth/send-mail", {
        email: "hoangphithietbidien@gmail.com",
        title: `Sản phẩm ${onePro.data?.title}`,
        content: sendmail,
        // from: values.email
      });
      message.success("Gửi báo giá thành công!");
      setIsOpF(false);
    } catch (error) {
      message.error("Kiểm tra lại dữ liệu gửi hoặc lỗi hệ thống");
    }
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {onePro.data ? (
        <div className="wrapDel">
          <div className="slideImg">
            <img
              src={onePro.data?.imgs[avtiveslide]?.url}
              alt="sss"
              onClick={() => setOpen(true)}
            />
            <div className="acSli">
              {onePro.data?.imgs.slice(0, 4).map((e, k) => (
                <div
                  className={avtiveslide === k ? "active" : ""}
                  key={k}
                  onClick={() => activeSlide(k)}
                >
                  <img src={e.url} alt={e.name} />
                </div>
              ))}
            </div>
          </div>
          <div className="info">
            <h1>{onePro.data?.title}</h1>
            {/* <h5>
              {Number(onePro.data?.price).toLocaleString("en-US")}&nbsp;
              <span>VNĐ</span>
            </h5> */}
            <p>
              Giá tốt hơn khi mua số lượng nhiều (vui lòng thêm vào báo giá để
              nhận báo giá hoặc qua email: thietbidienhoangphi@gmail.com)
            </p>
            <div className="box-w">
              <div className="box-w-f">
                <p>
                  <span>Mã sản phẩm</span>:
                  <br />
                  <span className="bl"> {onePro.data?.code}</span>
                </p>
                <p>
                  <span>Thương hiệu</span>:
                  <br />
                  <span className="bl"> {onePro.data?.brand}</span>
                </p>
              </div>
              <div className="box-w-f">
                {/* <p>
                  <span>Bảo hành</span>:
                  <br />
                  <span className="bl">{onePro.data?.warranty}</span>
                </p> */}
                {/* <p>
                  <span>Loại</span>:
                  <br />
                  <span className="bl"> MVX098</span>
                </p> */}
              </div>
            </div>
          </div>
          <p className="out-p">Số lượng</p>
          <InputNumber min={1} defaultValue={1} onChange={onChange} />
          <Button onClick={() => setIsOpF(true)}>Liên Hệ Nhanh</Button>

          <h3>Thông số kĩ thuật</h3>
          <div className="desc">
            <div dangerouslySetInnerHTML={{__html: onePro.data?.desc}}/>
          </div>

          <Modal
            open={open}
            title={onePro.data?.title}
            onCancel={() => setOpen(false)}
            footer={null}
            className="w-ad"
          >
            <div style={{ padding: "0 20px" }}>
              <Slider {...settings}>
                {onePro.data?.imgs.map((e, k) => (
                  <img src={e.url} alt={e.name} key={k}/>
                ))}
              </Slider>
            </div>
          </Modal>
          <Modal
            open={isOpF}
            title="Sản phẩm 1"
            onCancel={() => setIsOpF(false)}
            footer={null}
            destroyOnClose={true}
            className="w-ad"
          >
            <Table
              columns={columns}
              dataSource={onePro.data ? [onePro.data] : []}
              pagination={false}
            />
            <Form
              initialValues={{
                name: "",
                phone: "",
                email: "",
                content: "",
              }}
              layout="vertical"
              style={{ marginTop: "20px", width: "70%" }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Tên"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Nhập tên của bạn!",
                  },
                ]}
              >
                <Input placeholder="Nguyen An" />
              </Form.Item>

              <Form.Item
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Hãy nhập số điẹn thoại!",
                  },
                ]}
              >
                <Input type="number" placeholder="0908789789" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Hãy nhập email!",
                  },
                ]}
              >
                <Input placeholder="A@gmail.com" />
              </Form.Item>
              <Form.Item label="Nội dung ghi chú thêm" name="content">
                <Input.TextArea
                  style={{ height: "150px" }}
                  placeholder="Nội dung cần ghi chú"
                />
              </Form.Item>

              <Form.Item>
                <Button className="btn-modal" type="primary" htmlType="submit">
                  Gửi báo giá
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
}
