import React from "react";
import "./product.scss";
import AnhNen from "../../assets/bgadtag.jpg";
import LeftMenuProduct from "../../components/_shared/LeftMenuProduct";
import { Outlet } from "react-router-dom";
import { GoDash } from "react-icons/go";
import RightMenuBlog from "../../components/_shared/RightMenuBlog"

export default function Product() {
    const [contents, setContents] = React.useState({});

  const blogs = [
    {
      id: 1,
      img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1705575283/course/abga_xmpfsw.jpg",
      title: "Bài viết 1",
    },
    {
      id: 2,
      img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1705575283/course/abga_xmpfsw.jpg",
      title: "Bài viết 2",
    },
    {
      id: 3,
      img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1705575283/course/abga_xmpfsw.jpg",
      title: "Bài viết 3",
    },
    {
      id: 4,
      img: "https://res.cloudinary.com/hdprivatecloud/image/upload/v1705575283/course/abga_xmpfsw.jpg",
      title: "Bài viết 4",
    },
  ];
  return (
    <div className="wrapProduct">
      <div className="wrshort">
        <div>
          <img src={AnhNen} alt="anhnenproduct" />
          <div className="content_banner_home f-m">
            <h1>Sản Phẩm</h1>
            <p>
              <span> Trang Chủ</span>
              <span>
                <GoDash />
              </span>
              <span>Sản phẩm</span>
            </p>
          </div>
        </div>
      </div>
      <div className="main-l">
        <div>
          <LeftMenuProduct onFocusData={(e) => setContents(e)}/>
        </div>
        <div>
          <Outlet context={{...contents}}/>
        </div>
        <RightMenuBlog />
      </div>
    </div>
  );
}
